import classNames from 'classnames';
import { ReactElement } from 'react';
import './button.scss';

type Props = {
    onClick: () => void;
    text?: string;
    icon?: ReactElement;
    iconPlacement?: 'left' | 'right';
    size?: 'xl' | 'l' | 'm' | 's';
    category?: 'primary' | 'secondary' | 'tertiary' | 'error';
    isDisabled?: boolean;
};

const Button = ({
    onClick,
    text,
    icon,
    iconPlacement = 'left',
    size = 'm',
    category = 'primary',
    isDisabled = false,
}: Props) => {
    const buttonClass = classNames('button', {
        [`button--${size}`]: size,
        [`button--${category}`]: category,
        [`button__icon--${iconPlacement}`]: icon && iconPlacement,
        'button__icon--only': icon && !text,
    });

    return (
        <button type="button" onClick={onClick} className={buttonClass} disabled={isDisabled}>
            {icon && iconPlacement === 'left' ? icon : null}
            {text ? <span>{text}</span> : null}
            {icon && iconPlacement === 'right' ? icon : null}
        </button>
    );
};

export { Button };
