import { Button } from '@shared/ui';
import { useTranslation } from 'react-i18next';

const Forbidden = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="error-page__texts">
                <h3 className="error-page__title">{t('errorPage.forbidden.title')}</h3>
                <p>{t('errorPage.forbidden.subtitle')}</p>
            </div>
            <Button
                onClick={() => (window.location.href = '/myprojects')}
                category="primary"
                size="xl"
                text={t('errorPage.forbidden.button')}
            />
        </>
    );
};

export { Forbidden };
