import { useTranslation } from 'react-i18next';
import { BaseError } from './BaseError';

const Generic = () => {
    const { t } = useTranslation();

    return (
        <BaseError>
            <h3 className="error-page__title">{t('errorPage.generic.title')}</h3>
            <p>{t('errorPage.generic.subtitle')}</p>
        </BaseError>
    );
};

export { Generic };
