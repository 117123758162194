import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Forbidden, Generic, NotFound } from './errorTypes';

const Error = () => {
    const [searchParams] = useSearchParams();

    const errorComponent: Record<string, ReactElement> = {
        '': <Generic />,
        notFound: <NotFound />,
        forbidden: <Forbidden />,
    };

    return errorComponent[searchParams.get('error') || ''];
};

export { Error };
