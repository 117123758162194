import { useTranslation } from 'react-i18next';
import { BaseError } from './BaseError';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <BaseError>
            <h3 className="error-page__title">{t('errorPage.notFound.title')}</h3>
            <p>{t('errorPage.notFound.subtitle')}</p>
        </BaseError>
    );
};

export { NotFound };
