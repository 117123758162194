import { useTranslation } from 'react-i18next';
import Spinner from '@assets/icons/spinner/spinner.svg?react';
import './loader.scss';
import classNames from 'classnames';

type Props = {
    text?: 'projects' | 'data';
    size?: 's' | 'm' | 'l' | 'fullScreen';
};

const Loader = ({ text, size = 's' }: Props) => {
    const { t } = useTranslation();

    return (
        <div
            className={classNames('loader', {
                'loader--horizontal': text === 'data',
                'loader--vertical': text === 'projects',
                'loader--full-screen': size === 'fullScreen',
            })}
        >
            <Spinner
                className={classNames('loader__spinner', {
                    'loader__spinner--s': size === 's',
                    'loader__spinner--m': size === 'm',
                    'loader__spinner--l': size === 'l',
                })}
            />
            {text ? <span>{t(`loader.${text}`)}</span> : null}
        </div>
    );
};

export { Loader };
