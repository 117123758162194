import WarningIcon from '@assets/icons/alert.svg?react';
import { Error } from '@features/error';
import './errorPage.scss';

const ErrorPage = () => {
    return (
        <div className="error-page">
            <WarningIcon className="error-page__icon" />
            <Error />
        </div>
    );
};

export { ErrorPage as default };
