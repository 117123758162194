import HomeIcon from '@assets/icons/home.svg?react';
import { Button } from '@shared/ui';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

const BaseError = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="error-page__texts">{children}</div>
            <Button
                onClick={() => (window.location.href = '/')}
                category="primary"
                size="xl"
                icon={<HomeIcon />}
                text={t('errorPage.button')}
            />
        </>
    );
};

export { BaseError };
